<ng-container *transloco="let tu; read 'uploader'">
  <ngx-dropzone
    (change)="onSelect($event)"
    [accept]="acceptedFiles"
    [maxFileSize]="options.maxSize"
    [expandable]="!options.tiny"
    class="drop-zone"
    [class.--tiny]="options.tiny"
    >
    <ngx-dropzone-label class="reelax-black" *appLet="(appService.settings.asObservable() | async) as setting">
      @if (!options.tiny) {
        <object
          type="image/svg+xml"
          [data]="'assets/img/illustration/upload.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          class="upload-img"
          >
          <param name="color" [value]="illustrationColor">
        </object>
      }
      <p [class.tiny-text]="options.tiny">
        {{ tu('clickOrDragFilesHere') }}
      </p>
      @if (!options.tiny) {
        <p>
          {{ tu('acceptedDocuments', {acceptedFilesDisplay}) }}
        </p>
      }
    </ngx-dropzone-label>
    @if (!options.tiny) {
      @for (f of files; track f) {
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      }
    }
    @if (options.tiny) {
      @for (f of files; track f) {
        <app-uploader-tiny-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label class="no-click">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </app-uploader-tiny-preview>
      }
    }
  </ngx-dropzone>

  @if (!!error) {
    <p class="error fs-16 center mx-0 my-3">{{error}}</p>
  }
</ng-container>