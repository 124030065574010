import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessType } from '../../models';
import { AppService, SEOService, UserService } from '../../services';
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { LetDirective } from '../../shared/directives/let.directive';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    LoaderComponent,
    LetDirective,
    MatButton,
    AsyncPipe,
    SafePipe,
  ],
})
export class VerifyAccountComponent implements OnInit {
  processType = ProcessType;
  token: string;
  userAccountId: number;
  isUserVerified = false;
  isLoadingVerifying = true;
  isRegenerateDisabled = false;
  specificProcess: ProcessType;
  errors: string[];
  public validationType: 'account-unlock' | 'email-verification' = 'email-verification';

  constructor(
    public appService: AppService,
    private route: ActivatedRoute,
    private seoService: SEOService,
    private userService: UserService,
  ) {
    if (this.route.snapshot.url.map((u) => u.path).includes('account-unlock')) {
      this.validationType = 'account-unlock';
    }
    this.route.params.subscribe( (params) => {
      this.token = params.token;
    });
    this.route.queryParams.subscribe((params) => {
      if (params.isSellingProcess) {
        // legacy params was isSellingProcess so we maintain it for user clicking on old emails
        this.specificProcess = ProcessType.Selling;
      }
      this.specificProcess = params.specificProcess;
      this.userAccountId = params.userAccountId;
    });
    this.seoService.updateTitle('Vérification de compte');
  }

  ngOnInit() {
    this.verifyAccount();
  }

  verifyAccount() {
    const validation = this.validationType === 'email-verification' ?
      this.userService.validateUser(this.token, this.specificProcess, this.userAccountId) :
      this.userService.unlockAccount(this.token, this.userAccountId);
    validation.subscribe({
      next: (res) => {
        if (res) {
          this.isUserVerified = true;
          this.isLoadingVerifying = false;
        } else {
          this.isUserVerified = false;
          this.isLoadingVerifying = false;
        }
        if ( this.validationType === 'account-unlock') {
          this.isRegenerateDisabled = true;
        }
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on verifyAccount function on verify-account component: ', err);
        this.isUserVerified = false;
        this.isLoadingVerifying = false;
      },
    });
  }

  onRegenerateClick() {
    this.isRegenerateDisabled = true;
    this.errors = undefined;
    this.userService.regenerateToken(this.userAccountId, this.token, this.specificProcess).subscribe({
      next: (res) => {
        // sucess
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on onRegenerateClick function on verify-account component : ', err);
        if (err.error?.ResultCode === 999977) {
          this.errors = ['error', 'errorPersist'];
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });
  }

}
