import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AuthService,
  EventsService,
  NotificationService,
  TicketsService,
  UserService,
  PurchaseService,
  AppService,
} from '../../services';
import { Event, Bucket, UserProfile, Language, Organizer } from '../../models';
import { filter, Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { isPlatformBrowser, AsyncPipe, DecimalPipe } from '@angular/common';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { EventStore, OrganizerStore } from '../../stores';
import { MatSidenav } from '@angular/material/sidenav';
import { Utils } from '../../shared/utils';
import { LetDirective } from '../../shared/directives/let.directive';
import { BackButtonComponent } from '../../shared/components/back-button/back-button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatBadge } from '@angular/material/badge';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { LanguageSelectorComponent } from '../../shared/components/language-selector/language-selector.component';
import { MatToolbar } from '@angular/material/toolbar';
import { ReelaxTicketsLogoComponent } from '../../shared/components/reelax-tickets-logo/reelax-tickets-logo.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    LetDirective,
    BackButtonComponent,
    MatTooltip,
    MatBadge,
    MatMenuTrigger,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    LanguageSelectorComponent,
    MatToolbar,
    ReelaxTicketsLogoComponent,
    MatSidenav,
    MatMenu,
    MatMenuItem,
    MatButton,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') public sidenav: MatSidenav;

  environment = environment;
  isLandingPage = true;
  isProcessPages = false;
  currentUrl = '/';

  isBrowser: boolean;
  isTestMode = environment.test;
  needUserAction = false;

  public user: UserProfile;
  public buckets: Bucket[];
  public event: Event;
  public organizer: Organizer;
  public Language = Language;
  public hasOneTanslation = Utils.hasOneTanslation;

  private userSub: Subscription;
  private bucketsSub: Subscription;
  private bucketRemovalSub: Subscription;
  private routerSub: Subscription;
  private eventSub: Subscription;
  private organizerSub: Subscription;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public eventsService: EventsService,
    public purchaseService: PurchaseService,
    public appService: AppService,
    private eventStore: EventStore,
    private organizerStore: OrganizerStore,
    private notificationService: NotificationService,
    private router: Router,
    private ticketsService: TicketsService,
    private translocoService: TranslocoService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.handleLandingPage(this.router.url);
    this.routerSub = this.router.events.pipe(
      filter((val) => val instanceof NavigationEnd),
    ).subscribe((val: NavigationEnd) => {
      this.handleLandingPage(val.url);
      if (val.url.startsWith('/o/')) {
        this.event = undefined; // reset event if back on organizer event list
      }
    });
    this.userSub = this.authService.getUser().subscribe( (user) => {
      this.user = user;
      this.needUserAction = this.user?.needAction;
    });
    this.bucketsSub = this.userService.getBuckets().subscribe( (buckets) => {
      this.buckets = buckets;
    });
    this.organizerSub = this.organizerStore.currentOrganizerId$
      .subscribe( () => {
        this.organizer = this.organizerStore.getCurrentOrganizer();
      });
    this.eventSub = this.eventStore.currentEventId$
      .subscribe( () => {
        this.event = this.eventStore.getCurrentEvent();
      });
    if (this.isBrowser) {
      this.authService.forceReload();
      this.userService.forceReloadBuckets();
    }
    let wref = this.route.snapshot.queryParams?.wref;
    // if wref set multiple times only keep last value
    if (Array.isArray(wref)) {
      wref = wref.pop();
    }
    // set widget mode
    if (this.route.snapshot.queryParams?.wref) {
      this.appService.settings.next({
        ...this.appService.settings.value,
        isWidget: true,
        referer: wref ? wref : this.appService.settings.value?.referer,
      });
    }
  }

  handleLandingPage(url: string) {
    this.currentUrl = url;
    if (url === '/' || url.startsWith('/pro')) {
      this.isLandingPage = true;
    } else {
      this.isLandingPage = false;
    }
    if (url.startsWith('/e/n') || url.startsWith('/evenement/') ||
      url.startsWith('/o/') ||
      url.startsWith('/three-d-secure') || url.startsWith('/confirmation')) {
      this.isProcessPages = true;
    } else {
      this.isProcessPages = false;
    }
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe();
    this.bucketRemovalSub?.unsubscribe();
    this.bucketsSub?.unsubscribe();
    this.routerSub?.unsubscribe();
    this.eventSub?.unsubscribe();
    this.organizerSub?.unsubscribe();
  }

  removeBucketItem(bucket: Bucket) {
    this.bucketRemovalSub = this.purchaseService.removeBucketItem(bucket).subscribe((res) => {
      // If success, res is null
      // If failure, res = { ok: false, ... }
      if (!res || res.ok) {
        this.notificationService.open(this.translocoService.translate('bucket.ticketWithdrawn'));
      }
    });
  }

  closeSidenav() {
    this.sidenav?.close();
  }

  homeRedirection() {
    if (this.isLandingPage) {
      this.router.navigate(['/']);
      return;
    }
    if (this.currentUrl === '/evenements') {
      this.router.navigate(['/evenements']);
      return;
    }
    const eventId = this.eventsService.lastEventId;
    const eventUrl = this.eventsService.lastEventUrl;
    if (!!eventUrl && eventUrl !== '') {
      this.router.navigate(['/e/n/' + eventUrl]).then((routed) => {
        if (!routed) {
          this.ticketsService.forceTicketsListReload();
        }
      });
    } else if (!!eventId && eventId > 0) {
      this.router.navigate(['/evenement/' + eventId.toString()]).then((routed) => {
        if (!routed) {
          this.ticketsService.forceTicketsListReload();
        }
      });
    } else {
      this.router.navigate(['/evenements']);
    }
  }

}
