import { isPlatformBrowser, NgClass } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { interval, merge, Subscription, throwError, timer } from 'rxjs';
import { captureException } from '@sentry/angular';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { ColorService, PurchaseService } from '../../services';
import { Event, ReelaxError } from '../../models';
import { EventStore } from '../../stores';
import { HelpDirective } from '../../shared/directives/help.directive';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

type Fact = {
  fact: string,
  type: string,
};

@Component({
  selector: 'app-three-dsecure',
  templateUrl: './three-dsecure.component.html',
  styleUrls: ['./three-dsecure.component.scss'],
  standalone: true,
  imports: [
    HelpDirective,
    TranslocoModule,
    LoaderComponent,
    MatIcon,
    NgClass,
    MatButton,
    RouterLink,
  ],
})
export class ThreeDsecureComponent implements OnInit, OnDestroy {
  payementFailed = false;
  errorMessage = '';
  errorInfo = '';
  isCheckingPayment = true;
  waiting = false;

  environment = environment;

  transactionId;
  eventId;
  ticketId;
  wlPurchaseId;
  paymentToken: string;
  isBrowser: boolean;
  mangoErrorsMessages;
  event: Event;
  transationSub: Subscription;
  intervalSub: Subscription;
  eventSub: Subscription;

  private facts: Fact[];
  public fact1: Fact;
  public fact2: Fact;

  constructor(
    private eventStore: EventStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private purchaseService: PurchaseService,
    private colorService: ColorService,
    private translocoService: TranslocoService,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const params = this.activatedRoute.snapshot.params;
    this.eventId = params.eventId;
    this.ticketId = params.ticketId;
    this.wlPurchaseId = params.wlPurchaseId;
    this.paymentToken = params.paymentToken;

    this.transactionId = queryParams.transactionId || queryParams.preAuthorizationId;

    this.transationSub = merge(
      this.translocoService.selectTranslateObject('mangoErrors').pipe(
        tap( (mangoErrors) => {
          this.mangoErrorsMessages = mangoErrors;
        }),
      ),
      this.translocoService.selectTranslateObject('facts').pipe(
        tap( (facts) => {
          this.facts = facts;
          this.fact1 = this.pickRandomQuote(this.facts);
        }),
      ),
    ).subscribe();

    this.eventSub = this.eventStore.currentEventId$.pipe(
      tap( () => {
        this.event = this.eventStore.getCurrentEvent();
        if (this.event.color) {
          this.colorService.setEventColor(this.event);
        }
      }),
    ).subscribe({
      next: () => {},
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on getEvent in three d secure : ', err);
      },
    });
    this.doPaymentCheck();
  }

  pickRandomQuote(quotes) {
    return quotes[Math.floor(Math.random()*quotes.length)];
  }

  doPaymentCheck() {
    this.waiting = false;
    this.isCheckingPayment = true;
    this.intervalSub = interval(10000).subscribe(() => {
      if (this.fact1) {
        this.fact1 = null;
        this.fact2 = this.pickRandomQuote(this.facts);
      } else {
        this.fact2 = null;
        this.fact1 = this.pickRandomQuote(this.facts);
      }

    });
    if (this.ticketId) {
      this.handleTicketPaymentCheck();
    } else if (this.wlPurchaseId) {
      this.handleWaitlistPaymentCheck();
    }
  }

  handleTicketPaymentCheck() {
    const maxAttempts = 6;
    this.purchaseService.checkTicketPaymentStatus(this.ticketId, this.paymentToken, this.transactionId).pipe(
      tap( (res) => {
        if (res.Status === 'FAILED' && res.ResultCode === 9999989) {
          throw new ReelaxError(res.ResultMessage, res.ResultCode);
        }
      }),
      retry({
        count: maxAttempts,
        resetOnSuccess: true,
        delay: (error: ReelaxError, retryAttempt: number) => {
          if (retryAttempt >= maxAttempts && error.ResultCode === 9999989) {
            return throwError(() => error);
          }
          if (error.ResultCode !== 9999989) {
            return throwError(() => error);
          }
          return timer(5000);
        },
      }),
      catchError((err) => {
        if (err.ResultCode === 9999989) {
          return timer(5000).pipe(
            switchMap( () => this.purchaseService.checkTicketPaymentStatus(
              this.ticketId,
              this.paymentToken,
              this.transactionId,
            )),
          );
        }
        throw err;
      }),
    ).subscribe({
      next: (paymentStatus) => {
        if (paymentStatus.Status === 'FAILED') {
          this.setError(paymentStatus);
        }
        if (paymentStatus.Status === 'SUCESS') {
          const purchasedTickets = paymentStatus.TransactionData;
          let transactionType = 'buying';
          this.eventId = purchasedTickets[0].SaleRecord.eventId;
          this.ticketId = purchasedTickets[0].SaleRecord.ticketId;
          const token = purchasedTickets[0].SaleRecord.ticketToken;
          const ticketStatus = purchasedTickets[0].SaleRecord.status;
          this.isCheckingPayment = false;
          if (ticketStatus === 'pendingTicketDelivery') {
            transactionType = 'as-buying';
          }
          try {
            this.router.navigate(['/confirmation/', this.eventId, transactionType, this.ticketId, token]);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(paymentStatus);
            // eslint-disable-next-line no-console
            console.log('/confirmation/', this.eventId, transactionType, this.ticketId, token);
            captureException(error);
            this.router.navigate(['/confirmation/', this.eventId, 'as-buying', this.ticketId, '0']);
          }
          return;
        }

        if (paymentStatus.status === '3DSECURE' && paymentStatus.SecureModeNeeded && this.isBrowser) {
          window.location.href = paymentStatus.SecureModeRedirectURL;
          return;
        }
        this.setError(paymentStatus); // other error fallback
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on handleTicketPaymentCheck function on three-dsecure component :', err);
        this.errorMessage = 'Reelax Tickets Error';
        this.errorInfo = err.message;
        this.isCheckingPayment = false;
      },
    });
  }

  handleWaitlistPaymentCheck() {
    this.purchaseService.checkWaitlistPaymentStatus(this.wlPurchaseId, this.paymentToken, this.transactionId)
      .subscribe({
        next: (paymentStatus) => {
          if (paymentStatus.Status === 'FAILED') {
            this.setError(paymentStatus);
          }
          if (paymentStatus.Status === 'SUCESS') {
            const purchasedWlEntry = paymentStatus.TransactionData;
            this.eventId = purchasedWlEntry.eventId;
            this.isCheckingPayment = false;
            this.router.navigate([`/evenement/${this.eventId}/achat/attente/ok`]);
            return;
          }

          if (paymentStatus.status === '3DSECURE' && paymentStatus.SecureModeNeeded && this.isBrowser) {
            window.location.href = paymentStatus.SecureModeRedirectURL;
            return;
          }
          this.setError(paymentStatus); // other error fallback
        },
        error: (err) => {
          // eslint-disable-next-line no-console
          console.log('Error on handleWaitlistPaymentCheck function on three-dsecure component :', err);
        },
      });
  }

  setError(paymentStatus) {
    this.payementFailed = false;
    this.errorMessage = paymentStatus.ResultMessage;
    paymentStatus.ResultCode = parseInt(paymentStatus.ResultCode, 10);
    this.errorInfo = '';
    if (paymentStatus.ResultCode === 999999 || paymentStatus.ResultCode === 999998) {
      this.errorInfo = 'Reelax Tickets Error';
      this.payementFailed = true;
    } else if (paymentStatus.name === 'SaleError') {
      this.errorInfo = 'Reelax Tickets Sale Error';
      this.payementFailed = true;
    } else if (paymentStatus.name === 'SwapError') {
      this.errorInfo = 'Reelax Tickets Swap Error';
      this.payementFailed = true;
    } else if (paymentStatus.name === 'RedirectError') {
      this.errorInfo = 'Reelax Tickets Redirect Error';
      this.payementFailed = true;
    } else if (this.mangoErrorsMessages?.[paymentStatus.ResultCode]?.hasOwnProperty('MoreInformation')) {
      this.errorInfo = this.mangoErrorsMessages[paymentStatus.ResultCode].MoreInformation;
      this.payementFailed = true;
    } else if (paymentStatus.Message && !this.errorMessage) {
      this.errorMessage = paymentStatus.Message;
      this.errorInfo = JSON.stringify(paymentStatus.errors);
      this.payementFailed = false;
    }
    if (this.mangoErrorsMessages?.[paymentStatus.ResultCode]?.hasOwnProperty('ResultMessage')) {
      this.errorMessage = this.mangoErrorsMessages[paymentStatus.ResultCode].ResultMessage;
      this.payementFailed = true;
    }
    if (paymentStatus.ResultCode === 999979) {
      this.errorInfo = 'Payment identification error';
      this.payementFailed = false;
    }
    if (paymentStatus.ResultCode === 999943) {
      this.errorInfo = '';
      this.errorMessage = this.mangoErrorsMessages[paymentStatus.ResultCode]?.ResultMessage;
      this.payementFailed = false;
    }
    if (paymentStatus.ResultCode === 999976) {
      this.errorInfo = '';
      this.errorMessage = this.mangoErrorsMessages[paymentStatus.ResultCode]?.ResultMessage;
      this.payementFailed = false;
      if (this.isBrowser) {
        setTimeout( () => {
          this.waiting = true;
        }, 2000);
      }
    }
    this.isCheckingPayment = false;
    // case pending swap
    if (paymentStatus.ResultCode === 9999989) {
      this.errorInfo = '';
      this.errorMessage = '';
      this.payementFailed = false;
      this.isCheckingPayment = true;
      if (this.isBrowser) {
        setTimeout( () => {
          this.waiting = true;
        }, 2000);
      }
    }
  }

  ngOnDestroy() {
    this.transationSub?.unsubscribe();
    this.intervalSub?.unsubscribe();
    this.eventSub?.unsubscribe();
  }

}
