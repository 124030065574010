import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-server-fallback',
  templateUrl: './server-fallback.component.html',
  styleUrls: ['./server-fallback.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class ServerFallbackComponent {

  constructor() { }

}
