import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { Utils } from '../../../shared/utils';
import { WaitListConfig } from '../../../models';
import { Subscription } from 'rxjs';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { WaitlistGaugeComponent } from '../../../shared/components/waitlist-gauge/waitlist-gauge.component';

@Component({
  selector: 'app-waitlist-info-dialog',
  templateUrl: './waitlist-info-dialog.component.html',
  styleUrls: ['./waitlist-info-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    forwardRef(() => WaitlistGaugeComponent),
  ],
})
export class WaitlistInfoDialogComponent implements OnInit, OnDestroy {
  public waitListConfig: WaitListConfig;
  public randomFactor = 10;
  private translationSub: Subscription;
  private timeUnitTranslations;

  constructor(
    public dialogRef: MatDialogRef<WaitlistInfoDialogComponent>,
    private translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: {
      waitListConfig: WaitListConfig,
      gauge: {
        myRank
        currentRank
        randomNotifications,
        categoryName: string,
      }
      user,
      event: {color}
    },
  ) {
    this.waitListConfig = this.data.waitListConfig;
  }

  ngOnInit() {
    this.translationSub = this.translocoService.selectTranslateObject('timeUnit.medium')
      .subscribe((translations) => {
        this.timeUnitTranslations = translations;
      });
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }

  msToTime(ms: number): string {
    return Utils.msToTime(ms, this.timeUnitTranslations);
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }

}
