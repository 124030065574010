import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, AsyncPipe } from '@angular/common';
import { RESPONSE } from '../../../express.tokens';
import { Response } from 'express';
import { AppService, SEOService } from '../../services';
import { LetDirective } from '../../shared/directives/let.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button'; // required import for ssr build

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [
    LetDirective,
    TranslocoModule,
    LoaderComponent,
    RouterLink,
    MatButton,
    AsyncPipe,
  ],
})
export class NotFoundComponent implements OnInit {
  public isLoading = true;

  constructor(
    public appService: AppService,
    @Inject(PLATFORM_ID) private platformId,
    @Optional() @Inject(RESPONSE) private response: Response,
    private seoService: SEOService,
  ) {
    this.seoService.updateTitle('Page introuvable');
    this.seoService.setNotFound();
  }

  ngOnInit() {
    this.isLoading = false;

    if (!isPlatformBrowser(this.platformId)) {
      if (this.response) {
        this.response.status(404);
        this.response.statusMessage = '404 - Page Not Found';
      }
    }
  }

}
